.account-block{
  position: relative;
  border:1px solid $blue-gray;
  border-radius:17px;
  padding:30px 40px;
  margin-bottom:22px;
  @include mobile-down{
    padding:24px 18px;
    width:calc(100% + 28px);
    position: relative;
    left:-14px;
  }
  &.empty{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height:400px;
    text-align: center;
  }
  &.unverified{
    border:2px solid red;
  }
}
.add-account-holder{
  display:flex;
  justify-content: flex-end;
  align-items: center;
  h2{
    font-size:18px;
    font-weight: 900;
  }
  @include tablet-down{
    flex-direction: column;
    h2{
      margin-bottom:24px;
    }
  }
}
.add-account-buttons{
  button{
    border:1px solid #16BDAF;
    border-radius: 10px;
    min-width:186px;
    height:50px;
    font-size:18px;
    font-weight:700;
    padding:8px 16px;
    @include mobile-down{
      font-size:24px;
      line-height:unset;
      height: unset;
      width:100%;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    strong{
      font-size:20px;
      margin-right:3px;
      @include mobile-down{
        font-size:26px;
        line-height:0px;
      }
    }
    &:not(:last-child){
      margin-right:8px;
    }
    &:hover{
      background-color: #FC825C;
      color:#FFF;
    }
  }
}

.account-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom:22px;
  margin-bottom:25px;
  border-bottom:1px solid $blue-gray;
  button:not(.edit-account-btn){
    border:2px solid $red;
    border-radius: 10px;
    //width:66px;
    padding:8px;
    height:31px;
    font-size:14px;
    font-weight:500;
    line-height: 0px;
    &:hover{
      background-color:$red;
      color:#FFF;
    }
  }
  h2{
    font-size:18px;
    font-weight: 900;
  }
  .edit-account-btn{ 
    margin-top: 12px;
    border:2px solid #16BDAF;
    border-radius: 10px;
    padding:8px 16px;
    font-size:14px;
    font-weight:500;
    &:hover{
      background-color: #16BDAF;
      color:#FFF;
    }
  }
  .account-num{
    padding:8px 16px;
    font-size:14px;
    font-weight:500;
    display: block;
  }
}



.account-details{
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include tablet-down{
    flex-wrap: wrap;
    label,.label{
      margin-bottom:22px;
    }
  }
  label,.label{
    width:calc(50% - 16px);
    //max-width:450px;
    font-size:15px;
    font-weight: 400;
    margin-right:16px;
    @include mobile-down{
      width:100%;
    }
    &:last-child{
      max-width:120px;
    }
  }
}

.contribution-disclosure{
  &.accounts{
    text-align: right;
    padding:20px 20px;
    font-size:22px;
    font-weight:700;
    &.in-slide{
      padding:20px 30px;
    }
    @include mobile-down{
      width:calc(100% + 28px);
      position: relative;
      left:-14px;
      text-align: center;
      &.in-slide{
        width:100vw;
        border-radius: 0;
        left:-30px;
      }
    }
    @include mobile-tiny-down{
      padding:15px 10px;
    }
    span{
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 90px;
      color:#FFF;
      font-size: 18px;
      font-weight: 700;
      border:2px solid rgba(21,201,186,.56);
      background-color: #6062BB;
      border-radius: 19px;
      box-shadow: 0px 0px 3px 0px rgba(21,201,186,.56);
      padding:10px 10px 10px 16px;
      margin-left:16px;
      &.error{
        border:1px solid $red;
        background-color: $red;
        box-shadow: 0px 0px 3px 0px rgba($red,.56);
      }
      @include mobile-down{
        padding:8px 12px;
        margin-left:12px;
      }
      @include mobile-tiny-down{
        font-size: 12px;
        padding: 6px 8px;
        margin-left:5px;
      }
    }
  }
}

.accounts-header{
  position: relative;
  top:-30px;
  left:-30px;
  padding:30px 38px 30px 60px;
  width:calc(100% + 60px);
  border:1px solid $blue-gray;
  background-color: $lightest-gray;
  border-radius:17px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  .add-account-holder{
    button{
      border:3px solid #50529E;
      background-color: #15C9BA;
      color:#FFF;
      &:hover{
        background-color:#13A397;
      }
    }
  }
  @include mobile-down{
    padding:30px;
  }
}

.update-account-number-wrapper {
  display: block;
  margin-bottom: 8px;

  .loan-option-action {
    width: 100%;
    text-align: center;
    display: block;
    margin-bottom: 0 !important;
    font-size: 16px;
    opacity: .7;
    line-height: 1em;
  }

  .format-instructions {
    margin-top: 5px;
    font-size: 13px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    b {
      margin-right: 3px;
      white-space: nowrap;
    }

    span {
      opacity: .7;
    }
  }

}

.loan-option-action{
  margin-bottom:20px !important;
  a{
    font-size:18px;
    font-weight:700;
    color:rgb(20, 128, 217);
    &:hover{
      color:#140e99;
    }
  }
}

.loan-select-box{
  border: 1px solid rgba(0,0,0,.3);
    border-radius: 4px;
  display: grid;
  gap:12px;
  padding:12px;
  margin-bottom: 12px;
  position: relative;
  &::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    border-radius: 4px;
    background-color: rgba(0,0,0,.1);
    pointer-events: none;
  }
  &.active{
    &::after{
      display: none;
    }
  }
  .single-selector{
    margin-bottom:0;
  }
  span{
    text-align: left;
  }
}

.priority-arrows{
  position: absolute;
  top:12px;
  left:8px;
  display: grid;
  button{
    padding:1px 8px;
    &:first-child{
      border-bottom: 1px solid rgba(0,0,0,.1);
    }
  }
}
