.page{
  min-height:calc(100vh - 95px);
  position: relative;
  background: #50529e;
  background: -moz-linear-gradient(225deg, #36366e 0%, #6365c1 100%);
  background: -webkit-linear-gradient(225deg, #36366e 0%,#6365c1 100%);
  background: linear-gradient(225deg, #36366e 0%,#6365c1 100%);
  padding-bottom:75px;
  &.auth{
    min-height:calc(100vh - 250px);
    @include tablet-down{
      min-height:calc(100vh - 270px);
    }
    @include mobile-down{
      min-height:calc(100vh - 320px);
    }
  }
  &::before{
    content:"";
    position: absolute;
    width:100%;
    height:490px;
    left:0;
    top:0;
    background-image: url("/images/top-wave.svg");
    background-repeat: no-repeat;
    background-position: left 50% bottom 0;
    background-size:cover;
  }
  @include tablet-down{
    &::before{
      display: none;
    }
  }
  @include mobile-down{
    padding-bottom:0;
  }
  &>.header{
    position: relative;
    padding:0 30px;
    width:calc(100% - 200px);
    max-width: $max-width;
    margin:90px 0 50px;
    color:#FFF;
    display:flex;
    align-items: center;
    justify-content: space-between;
    .heading{
      font-size: 36px;
      line-height:48px;
      color:#FFF;
    }
    .sub-heading{
      font-size:18px;
      color:#FFF;
      opacity: .5;
    }
    .button-holder{
      height:fit-content;
      white-space: nowrap;
    }
    @include tablet-down{
      width:calc(100% - 66px);
      margin:80px 0 50px;
      flex-direction: column;
      align-items: flex-start;
      .button-holder{
        margin-top:28px;

      }
    }
    @include mobile-down{
      width:100%;
      margin:90px 0 40px;
      .heading{
        margin-bottom:10px;
      }
      .button-holder{
        width:100%;
        button{
          width:100%;
        }
      }
    }
  }
  .sub-header{
    margin-bottom:60px;
    &:first-of-type{
      margin-bottom:40px;
    }
    h2{
      font-size:24px;
      color:$purple;
    }
    p{
      font-size:14px;
      color:#000;
      opacity: .5;
    }
  }
}

.list-section{
  width:100%;
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  z-index:5;
  .body{
    background-color:#FFF;
    border-radius:17px;
    box-shadow: 0px 0px 24px 0px rgba(0,0,0,.3);
    padding:50px 30px;
    width:calc(100% - 200px);
    max-width: $max-width;
    @include tablet-down{
      width:calc(100% - 66px);
      padding:45px 25px;
    }
    @include mobile-down{
      width:100%;
      border-radius: 0;
      padding:50px 30px;
      box-shadow:none;
    }
  }
  .search-filter-container{
    margin-bottom:50px;
  }
  .table-row{
    padding:36px 14px 36px 30px;
    span{
      font-size: 17px;
      color:#000;
    }
    &.header{
      span{
        font-size:15px;

      }
    }

    .title{
      font-size:15px;
      color:#000;
      margin-bottom:5px;
    }
  }
}

.company-account-status{
  h1{
    display: flex;
    align-items: center;
    @include mobile-down{
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .button-holder{
    button{
      &:not(:last-child){
        margin-right:24px;
      }
    }
  }
  .account-status{
    text-transform: capitalize;
    margin-left:16px;
    background-color: #FFF;
    border-radius:38px;
    height:42px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:10px 22px;
    color:#50529E;
    font-size:19px;
    font-weight:400;
    width:fit-content;
    @include mobile-down{
      margin-left:0;
      margin-top:16px;
    }
    img{
      border-radius: 100%;
      background-color:#FFA700;
      margin-right:10px;
      max-width:15px;
      width:15px;
    }
  }
  h2{
    color:#3F417E;
    margin:0 0 18px;
  }
  .notification{
    padding:26px 30px;
    border-radius:5px;
    font-size:14px;
    background-color: #F5F7FC;
    margin-bottom:8px;
    &.error{
      background-color: #FF000021;
    }
    &.alert{
      background-color: #FFF5E3;
    }

  }
  .toggle-notifications{
    margin:31px 0;
    display: block;
    color: #3F417E;
    font-size:14px;
  }
  .document-list-block{
    background-color:#F5F7FC;
    border-radius:5px;
    padding:15px 16px;
    margin-top:34px;

    label,.label{
      font-size:14px;
      font-weight:400;
      display: block;
      max-width:616px;
      margin:30px auto;
      .file-input-container, input,.single-selector{
        background-color: #FFF;
      }
    }
    &.field-list{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      label,.label{
        max-width:100%;
        width:calc(50% - 40px);
        margin:30px 20px;
        &.full-row{
          width:100%;
          &.right{
            text-align: right;
          }
        }

        @include med-tablet-down{
          width:100%;
        }
      }
    }
  }
}
