@mixin subnav-icons-default{
  &.employees{
    span{
      color:#5D5FB7;
    }
  }
  &.payroll{
    span{
      color:#17D6C6;
    }
  }
  &.elections{
    span{
      color:#FC825C;
    }
  }
  &.invoice{
    span{
      color:#1480D9;
    }
  }
}

.dashboard-header{
  position: relative;
  &:not(.employee) {
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 1);
      background: -moz-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.94) 7%, rgba(0, 0, 0, 0.15) 92%, rgba(0, 0, 0, 0.08) 100%);
      background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(7%, rgba(0, 0, 0, 0.94)), color-stop(92%, rgba(0, 0, 0, 0.15)), color-stop(100%, rgba(0, 0, 0, 0.08)));
      background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.94) 7%, rgba(0, 0, 0, 0.15) 92%, rgba(0, 0, 0, 0.08) 100%);
      background: -o-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.94) 7%, rgba(0, 0, 0, 0.15) 92%, rgba(0, 0, 0, 0.08) 100%);
      background: -ms-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.94) 7%, rgba(0, 0, 0, 0.15) 92%, rgba(0, 0, 0, 0.08) 100%);
      background: linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.94) 7%, rgba(0, 0, 0, 0.15) 92%, rgba(0, 0, 0, 0.08) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=1);
      opacity: .3;
    }
  }
  &:not(.simple) {
    overflow: hidden;
    &::after {
      display: block;
      content: "";
      width: calc(100% + 8px);
      height: 102px;
      position: absolute;
      bottom: -1px;
      left: -4px;
      background: url("/images/hero-wave-mask.svg") no-repeat left 0 top 0;
      background-size: cover;
      @media(max-width: 1400px) {
        background-size: contain;
        background-position: left 0 bottom 0;
      }
      @include mobile-down {
        display: none;
      }
    }
  }
}
.dashboard-header-holder{
  position: relative;
  display: flex;
  
  justify-content: space-between;
  align-items: center;
  padding:125px 120px 125px 160px;
  &.simple{
    padding-top:90px;
    padding-bottom:70px;
    .dashboard-header-content{
      max-width:100%;
    }
  }
  &.employee{
    gap:30px;
    align-items: flex-start;
    .simple-rounded-button{
      padding:12px 16px;
    }
    @include tablet-down{
      padding-bottom: 30px;
      .simple-rounded-button{
        white-space: normal;
        br{
          display: none;
        }
      }
    }
    @include mobile-down{
      padding-bottom:20px !important;
    }
  }
  @include tablet-down{
    flex-direction: column;
    padding:82px 110px 110px;
    &.simple {
      padding-top: 82px;
      padding-bottom: 50px;
    }
  }
  @include mobile-down{
    padding:57px 35px 87px !important;
  }
}
.dashboard-header-content{
  max-width:437px;
  margin-right:20px;
  color:#FFF;
  @include tablet-down{
    margin-right:0;
    &:not(.simple) {
      margin-bottom: 55px;
    }
  }
  h1{
    font-size:32px;
    line-height:34px;
    font-weight:900;
    margin-bottom:20px;
  }
  p{
    font-size:24px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom:18px;
  }
}
.header-file-box{
  width:548px;
  max-width:100%;
  height:394px;
  padding:22px;
  background-color:#FFF;
  box-shadow: 0px 0px 50px 0px rgba(0,0,0,.5);
  border-radius:30px;
  .inner-box{
    width:100%;
    height:100%;
    border:4px dotted rgb(181,182,214);
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    padding:4px;

  }
  .content{
    position: relative;
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top:30px;
    .upload-message{
     position: absolute;
      bottom:22px;
      padding:0 16px;
      max-width: 100%;
      text-align: center;
      font-family: lato, sans-serif;
      font-size:16px;
      font-weight: 600;
      max-height:50px;
      overflow: hidden;
      &.error{
        color:darken(#F67281,20%);
        font-weight: 900;
      }
    }
    a{
      color:#FFF;
      @include mobile-small-down{
        font-size:16px !important;
      }
    }
  }
  h2{
    font-size:24px;
    font-weight:900;
    line-height:32px;
    margin-bottom:5px;
    text-align: center;
  }
  p{
    font-family: lato, sans-serif;
    font-size:20px;
    font-weight: 600;
    margin-bottom:28px;
  }
  button{
    color:#FFF;

  }
  .template{
    text-align: center;
    border-top:4px dotted rgb(181,182,214);
    padding:20px 10px 16px;
    color:#000;
    span{
      margin-right:8px;
    }
  }
}
.upload-progress-bar-holder{
  width:100%;
  padding:0 16px;
  position: absolute;
  bottom:22px;
  .upload-progress-bar{
    height:10px;
    background-color: rgb(245,247,252);
    span{
      display: block;
      height:10px;
      background-color: rgb(2,193,175);
      transition: width .3s;
    }
  }
}
.down-anchor{
  display: block;
  $size:39px;
  width:$size;
  min-width:$size;
  height:$size;
  min-height:$size;
  border:1px solid rgba(255,255,255,.5);
  border-radius: 100%;
  background:url("/images/arrow-down-full-white.svg") no-repeat center;
  &:hover{
    border:1px solid #FFF;
  }
}

.sub-nav-holder{
  width:100%;
  max-width:1218px;
  border-radius:61px;
  margin:50px auto 100px;
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding:25px 28px;
  background-color: #F5F7FC;
  border:1px solid $light-gray;
  @include tablet-down{
    padding:12px 13px;
  }
  @include mobile-down{
    &.desktop-nav {
      border-radius: 0;
      margin: 0 0 88px;
      min-width: 100vw;
      max-width: unset;
      position: relative;
      &.popup-nav{
        min-width: 100%;
        border:none;
        margin:0;
        span.active{
          height:71px !important;
        }

      }
      a,&>span{
        width: 100%;
        background-color: #FFF !important;
        color: #333333 !important;
        margin:0 !important;
        &:not(.active) {
          display: none;
        }
        &.active{
          &::after{
            position: relative;
            content:"";
            width:10px;
            height:10px;
            background-image:url('/images/down-chevron-blue.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size:cover;
            margin-left:18px;
            top:-2px;
          }
        }
        @include subnav-icons-default;
      }
    }
  }
  a,&>span{
    width:fit-content;

    height:71px;
    border:1px solid $light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 36px;
    background-color:#FFF;
    color:#333333;
    font-size:20px;
    font-weight:500;
    padding:0 20px;
    white-space: nowrap;
    &.tab-nav{
      //white-space: normal;
    }
    &:not(:last-child){
      margin-right:10px;
    }

    span{
      margin-right:10px;
      font-size:24px;
      background:none;
    }
    @include tablet-down{
      //font-size:20px;
      span{
        font-size:20px;
        margin-right:5px;
      }
    }
    @include med-tablet-down{
      font-size: 17px;
      border: none;
      background-color:unset;
    }
    @include mobile-down{
      font-size:20px;
    }

    @include subnav-icons-default;

    &:hover,&.active{
      border:2px solid #FFF;
      color:#FFF ;
      box-shadow: 0px 0px 8px 0px rgba(0,0,0,.2);
      font-weight:700;
      &.tab-nav{
        background-color: $purple;
      }
        span {
          color: #FFF;
        }

      &.employees{
        background-color:#5D5FB7;
      }
      &.payroll{
        background-color:#17D6C6;
      }
      &.elections{
        background-color:#FC825C;
      }
      &.invoice{
        background-color:#1480D9;
      }

    }

  }
}
.mobile-subnav-holder{
  width:100%;
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a,&>span{
    position: relative;
    padding:20px 10px;
    color:#000;
    display:flex;
    align-items: center;
    width:calc(100% + 28px);
    justify-content: center;
    font-size:20px;
    font-weight:600;
    &.tab-nav{
      color:#000 !important;
      width:100% !important;
      height: unset !important;
    }
    @include mobile-tiny-down{
      width:calc(100% + 24px);
    }
    &:not(:last-child){
      border-bottom:1px solid rgb(228,233,245);
    }
    &.active{
      background-color:rgb(245,247,252);
    }
    span{
      font-size:26px;
      margin-right:10px;

    }
    @include subnav-icons-default;
  }
}
#dashboard-details{
  margin-bottom:140px;
  @include mobile-down{
    margin-bottom:50px;
  }
  h1{
    font-size:36px;
    font-weight: 900;
    line-height:48px;
    margin-bottom:16px;
    @include mobile-down{
      font-size:24px;
    }
  }
  .sub-header{
    display: block;
    font-size:18px;
    line-height: 24px;
    opacity: .5;
    @include mobile-down{
      font-size:17px;
    }
  }
}
.dashboard-content{
  min-height:calc(100vh - 425px);
  @include tablet-down{
    padding:0 24px;
  }
  @include mobile-down{
    padding:0 14px;
  }
}
.content-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 100px;
  &>.title {
    a, p {
      font-size: 18px;
      line-height: 24px;
      color: #000;
      opacity: .5;
    }
  }
  .buttons {
    & > button {
      padding: 17px 42px;
      color: #FFF;
      &:not(:last-child){
        margin-right:16px;
      }
    }
    @include med-tablet-down{
      & > button{
        display: block;
        width:100%;
        &:not(:last-child){
          margin-bottom:10px;
        }
        &:not(:last-child){
          margin-right:0;
        }
      }
    }
    @include mobile-down{
      width:100%;
      margin-bottom:56px;
    }
  }
  @include mobile-down{
    flex-direction: column;
    margin-bottom: 0;
    align-items: flex-start;
    &>.title,&>button{
      width:100%;
      margin-bottom:44px;
    }
  }
}
.search-filter-container{
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:93px;
  &.employees{
    margin-bottom:50px;
  }
  .download-all-employees{

  }
  input{
    width:100%;
    border-radius:25px;
    border:1px solid $light-gray;
    background-color:#FFF;
    background:url('/images/search.svg') no-repeat right 25px bottom 50%;
    padding:16px 50px 16px 35px;
  }
  @include mobile-down{
    flex-direction: column;
    margin-bottom:50px;
    input{
      margin-bottom:15px;
    }
  }
}

.padded-inner-page{
  @include tablet-up{
    padding:0 45px;
  }
  h3{
    font-size:24px;
    line-height: 28px;
    font-weight: 900;
    letter-spacing: 0px;
    margin-bottom:43px;
  }
  &>p{
    margin-bottom:24px;
  }
  &>button{
    margin-bottom: 75px;
  }
  .evoshare-amounts{
    display: flex;
    border: 1px solid #E4E9F5;
    border-radius: 10px;
    margin-bottom: 27px;

    &>div{
      padding:30px 48px;
      width:50%;
      h1{
        margin-bottom:0 !important;
        color:$green;
      }
      &:first-child{
        border-right:1px solid #E4E9F5;
      }
    }
    @include mobile-down{
      flex-direction: column;
      &>div{
        width:100%;
        &:first-child{
          border-right:none;
          border-bottom:1px solid #E4E9F5;
        }
      }
    }
  }
  .chart-placeholder{
    min-width:100%;
    min-height: 100%;
  }
  .evoshare-chart{
    background-color: rgb(245,247,252);
    position: relative;

    .evoshare-wrapper{
      max-width:100%;
      overflow: hidden;
    }
    .recharts-wrapper{
      left:-5px;
      bottom:-4px;
    }
    .evoshare-chart-date{
      color:#666;
      position: absolute;
      &.start{
        left:0;
      }
      &.end{
        right:0;
      }
    }
  }
}

.evhoshare-login{
  display: block;
  color: #16BDAF;
  text-decoration: underline;
  font-size: 22px;
  font-weight: 700;
  margin: -20px 0 30px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.link-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include mobile-down{
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    button{
      margin-top:20px;
    }
  }
}

.cancel-instructions{
  position: relative;
  z-index: 10;
  margin-top:30px;
  margin-bottom:-20px;
  text-align: left;
  width:100%;
  a{
    color:#000;
    font-weight:500;
    &:hover{
      text-decoration: underline;
    }
  }
}
