.tab-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 24px;
    gap: 12px;

    @media screen and (max-width: 480px) {
        overflow: scroll;
        scrollbar-width: none;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .tab {
        color: #3F417E;
        background-color: #FFF;
        border: 1px solid rgba(17, 17, 17, 0.2);
        border-radius: 20px;
        padding: 8px 14px;
        cursor: pointer;
        font-weight: 500;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-size: 1.5em;
        &.active {
            border: 1px solid rgba(17, 17, 17, 0.4);
            background-color: rgba(241, 241, 241, 1);
            color: #FFF;
            background-color: #3F417E;
        }
    }
}

.tab-content-container {
    .tab-content:not(.active) {
        display: none;
    }
}
